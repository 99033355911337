/*
| Developed by Dirupt
| Filename : settings-context.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

'use client'

import React from 'react'
import { SettingsContextProps } from '../types'

/*
|--------------------------------------------------------------------------
| Create context
|--------------------------------------------------------------------------
*/
export const SettingsContext = React.createContext({} as SettingsContextProps)

/*
|--------------------------------------------------------------------------
| Hook
|--------------------------------------------------------------------------
*/
export const useSettingsContext = () => {
	const context = React.useContext(SettingsContext)

	if (!context) throw new Error('useSettingsContext must be use inside SettingsProvider')

	return context
}
