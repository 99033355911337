/*
| Developed by Dirupt
| Filename : index.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

'use client'

import { useMemo } from 'react'
import merge from 'lodash/merge'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeOptions, ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { palette } from './palette'
import { shadows } from './shadows'
import { typography } from './typography'
import { customShadows } from './custom-shadows'
import { componentsOverrides } from './overrides'
import { createPresets } from './options/presets'
import { createContrast } from './options/contrast'
import NextAppDirEmotionCacheProvider from './next-emotion-cache'
import { useSettingsContext } from '@/components/settings'
import { useLocales } from '@/locales'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
type Props = {
	children: React.ReactNode
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export default function ThemeProvider({ children }: Props) {
	const { currentLang } = useLocales()
	const settings = useSettingsContext()
	const presets = createPresets(settings.themeColorPresets)
	const contrast = createContrast(settings.themeContrast, settings.themeMode)

	const memoizedValue = useMemo(
		() => ({
			palette: {
				...palette(settings.themeMode),
				...presets.palette,
				...contrast.palette,
			},
			customShadows: {
				...customShadows(settings.themeMode),
				...presets.customShadows,
			},
			shadows: shadows(settings.themeMode),
			shape: { borderRadius: 8 },
			typography,
		}),
		[settings.themeMode, presets.palette, presets.customShadows, contrast.palette],
	)

	const theme = createTheme(memoizedValue as ThemeOptions)

	theme.components = merge(componentsOverrides(theme), contrast.components)

	const themeWithLocale = useMemo(() => createTheme(theme, currentLang.systemValue), [currentLang.systemValue, theme])

	return (
		<NextAppDirEmotionCacheProvider options={{ key: 'css' }}>
			<MuiThemeProvider theme={themeWithLocale}>
				<CssBaseline />
				{children}
			</MuiThemeProvider>
		</NextAppDirEmotionCacheProvider>
	)
}
