/*
| Developed by Dirupt
| Filename : useActiveLink.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import { usePathname } from 'next/navigation'

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
type ReturnType = boolean

/*
|--------------------------------------------------------------------------
| Hook
|--------------------------------------------------------------------------
*/
export function useActiveLink(path: string, deep = true): ReturnType {
	const pathname = usePathname()
	const checkPath = path.startsWith('#')
	const currentPath = path === '/' ? '/' : `${path}/`
	const normalActive = !checkPath && pathname === currentPath
	const deepActive = !checkPath && pathname.includes(currentPath)

	return deep ? deepActive : normalActive
}
