/*
| Developed by Dirupt
| Filename : paper.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import { Theme, alpha } from '@mui/material/styles'

/*
|--------------------------------------------------------------------------
| Paper
|--------------------------------------------------------------------------
*/
export function paper(theme: Theme) {
	return {
		MuiPaper: {
			styleOverrides: {
				root: {
					backgroundImage: 'none',
				},
				outlined: {
					borderColor: alpha(theme.palette.grey[500], 0.16),
				},
			},
		},
	}
}
