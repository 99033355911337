/*
| Developed by Dirupt
| Filename : localization-provider.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

'use client'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useLocales } from './useLocales'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
type Props = {
	children: React.ReactNode
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export default function LocalizationProvider({ children }: Props) {
	const { currentLang } = useLocales()

	return (
		<MuiLocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={currentLang.adapterLocale}>
			{children}
		</MuiLocalizationProvider>
	)
}
