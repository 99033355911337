/*
| Developed by Dirupt
| Filename : appbar.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import { Theme } from '@mui/material/styles'

/*
|--------------------------------------------------------------------------
| AppBar
|--------------------------------------------------------------------------
*/
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function appBar(_theme: Theme) {
	return {
		MuiAppBar: {
			styleOverrides: {
				root: {
					boxShadow: 'none',
				},
			},
		},
	}
}
