/*
| Developed by Dirupt
| Filename : progress.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import { Theme, alpha } from '@mui/material/styles'
import { LinearProgressProps, linearProgressClasses } from '@mui/material/LinearProgress'

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/
const COLORS = ['primary', 'secondary', 'info', 'success', 'warning', 'error'] as const

/*
|--------------------------------------------------------------------------
| Progress
|--------------------------------------------------------------------------
*/
export function progress(theme: Theme) {
	const rootStyles = (ownerState: LinearProgressProps) => {
		const bufferVariant = ownerState.variant === 'buffer'

		const defaultStyle = {
			borderRadius: 4,
			[`& .${linearProgressClasses.bar}`]: {
				borderRadius: 4,
			},
			...(bufferVariant && {
				backgroundColor: 'transparent',
			}),
		}

		const colorStyle = COLORS.map((color) => ({
			...(ownerState.color === color && {
				backgroundColor: alpha(theme.palette[color].main, 0.24),
			}),
		}))

		return [defaultStyle, ...colorStyle]
	}

	return {
		MuiLinearProgress: {
			styleOverrides: {
				root: ({ ownerState }: { ownerState: LinearProgressProps }) => rootStyles(ownerState),
			},
		},
	}
}
