/*
| Developed by Dirupt
| Filename : useLocales.ts
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

'use client'

import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { allLangs, defaultLang } from './config-lang'
import { LocalStorageService } from '@/services/local-storage/local-storage.service'

/*
|--------------------------------------------------------------------------
| Hook for locales
|--------------------------------------------------------------------------
*/
export function useLocales() {
	const langStorage = LocalStorageService.localStorageGetItem('i18nextLng')

	const currentLang = allLangs.find((lang) => lang.value === langStorage) || defaultLang

	return {
		allLangs,
		currentLang,
	}
}

/*
|--------------------------------------------------------------------------
| Hook for translate
|--------------------------------------------------------------------------
*/
export function useTranslate() {
	const { t, i18n, ready } = useTranslation()

	const onChangeLang = useCallback(
		(newlang: string) => {
			void i18n.changeLanguage(newlang)
		},
		[i18n],
	)

	return {
		t,
		i18n,
		ready,
		onChangeLang,
	}
}
