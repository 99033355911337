/*
| Developed by Dirupt
| Filename : config-lang.ts
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

'use client'

import merge from 'lodash/merge'
import { fr as frFRAdapter, enUS as enUSAdapter } from 'date-fns/locale'
import { enUS as enUSDate, frFR as frFRDate } from '@mui/x-date-pickers/locales'
import { enUS as enUSCore, frFR as frFRCore } from '@mui/material/locale'
import { enUS as enUSDataGrid, frFR as frFRDataGrid } from '@mui/x-data-grid'

/*
|--------------------------------------------------------------------------
| Configuration of the languages
|--------------------------------------------------------------------------
|
| Please remove `LOCAL STORAGE` when you change settings.
| Get more country flags: https://icon-sets.iconify.design/flagpack/
| or https://www.dropbox.com/sh/nec1vwswr9lqbh9/AAB9ufC8iccxvtWi3rzZvndLa?dl=0
|
*/
export const allLangs = [
	{
		label: 'English',
		value: 'en',
		systemValue: merge(enUSDate, enUSDataGrid, enUSCore),
		adapterLocale: enUSAdapter,
		icon: 'flagpack:gb-nir',
		numberFormat: {
			code: 'en-US',
			currency: 'USD',
		},
	},
	{
		label: 'French',
		value: 'fr',
		systemValue: merge(frFRDate, frFRDataGrid, frFRCore),
		adapterLocale: frFRAdapter,
		icon: 'flagpack:fr',
		numberFormat: {
			code: 'fr-Fr',
			currency: 'EUR',
		},
	},
]

export const defaultLang = allLangs[0] // English
