/*
| Developed by Dirupt
| Filename : palette.ts
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import { alpha } from '@mui/material/styles'

/*
|--------------------------------------------------------------------------
| Extend Palette
|--------------------------------------------------------------------------
*/
declare module '@mui/material/styles/createPalette' {
	interface TypeBackground {
		neutral: string
		neutralLight: string
	}
	interface SimplePaletteColorOptions {
		lighter: string
		darker: string
	}
	interface PaletteColor {
		lighter: string
		darker: string
	}
}

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error'

/*
|--------------------------------------------------------------------------
| Palette
|--------------------------------------------------------------------------
*/
export const grey = {
	0: '#FFFFFF',
	100: '#F9FAFB',
	200: '#F4F6F8',
	300: '#DFE3E8',
	400: '#C4CDD5',
	500: '#919EAB',
	600: '#637381',
	700: '#454F5B',
	800: '#212B36',
	900: '#161C24',
}

export const primary = {
	lighter: '#4cabff',
	light: '#0c64ff',
	main: '#054ef4',
	dark: '#054ef4',
	darker: '#0f245c',
	contrastText: '#FFFFFF',
}

export const secondary = {
	lighter: '#ccfffe',
	light: '#5efdff',
	main: '#05e9f4',
	dark: '#00d2e0',
	darker: '#068598',
	contrastText: '#FFFFFF',
	// contrastText: '#115868',
}

export const info = {
	lighter: '#CAFDF5',
	light: '#61F3F3',
	main: '#00B8D9',
	dark: '#006C9C',
	darker: '#003768',
	contrastText: '#FFFFFF',
}

export const success = {
	lighter: '#D3FCD2',
	light: '#77ED8B',
	main: '#22C55E',
	dark: '#118D57',
	darker: '#065E49',
	contrastText: '#ffffff',
}

export const warning = {
	lighter: '#FFF5CC',
	light: '#FFD666',
	main: '#FFAB00',
	dark: '#B76E00',
	darker: '#7A4100',
	contrastText: grey[800],
}

export const error = {
	lighter: '#FFE9D5',
	light: '#FFAC82',
	main: '#FF5630',
	dark: '#B71D18',
	darker: '#7A0916',
	contrastText: '#FFFFFF',
}

export const common = {
	black: '#000000',
	white: '#FFFFFF',
}

export const action = {
	hover: alpha(grey[500], 0.08),
	selected: alpha(grey[500], 0.16),
	disabled: alpha(grey[500], 0.8),
	disabledBackground: alpha(grey[500], 0.24),
	focus: alpha(grey[500], 0.24),
	hoverOpacity: 0.08,
	disabledOpacity: 0.48,
}

const base = {
	primary,
	secondary,
	info,
	success,
	warning,
	error,
	grey,
	common,
	divider: alpha(grey[500], 0.2),
	action,
}

/*
|--------------------------------------------------------------------------
| Palette
|--------------------------------------------------------------------------
*/
export function palette(mode: 'light' | 'dark') {
	const light = {
		...base,
		mode: 'light',
		text: {
			primary: grey[800],
			secondary: grey[600],
			disabled: grey[500],
		},
		background: {
			paper: '#FFFFFF',
			default: '#FFFFFF',
			neutral: grey[200],
			neutralLight: '#FBFBFC',
		},
		action: {
			...base.action,
			active: grey[600],
		},
	}

	const dark = {
		...base,
		mode: 'dark',
		text: {
			primary: '#FFFFFF',
			secondary: grey[500],
			disabled: grey[600],
		},
		background: {
			paper: grey[800],
			default: grey[900],
			neutral: alpha(grey[500], 0.12),
			neutralLight: alpha(grey[200], 0.12),
		},
		action: {
			...base.action,
			active: grey[500],
		},
	}

	return mode === 'light' ? light : dark
}
