/*
| Developed by Dirupt
| Filename : svg-icon.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import { Theme } from '@mui/material/styles'

/*
|--------------------------------------------------------------------------
| SvgIcon
|--------------------------------------------------------------------------
*/
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function svgIcon(_theme: Theme) {
	return {
		MuiSvgIcon: {
			styleOverrides: {
				fontSizeLarge: {
					width: 32,
					height: 32,
					fontSize: 'inherit',
				},
			},
		},
	}
}
