/*
| Developed by Dirupt
| Filename : popover.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import { Theme } from '@mui/material/styles'
import { listClasses } from '@mui/material/List'
import { paper } from '../../css'

/*
|--------------------------------------------------------------------------
| Popover
|--------------------------------------------------------------------------
*/
export function popover(theme: Theme) {
	return {
		MuiPopover: {
			styleOverrides: {
				paper: {
					...paper({ theme, dropdown: true }),
					[`& .${listClasses.root}`]: {
						paddingTop: 0,
						paddingBottom: 0,
					},
				},
			},
		},
	}
}
