/*
| Developed by Dirupt
| Filename : menu.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import { Theme } from '@mui/material/styles'
import { menuItem } from '../../css'

/*
|--------------------------------------------------------------------------
| Menu
|--------------------------------------------------------------------------
*/
export function menu(theme: Theme) {
	return {
		MuiMenuItem: {
			styleOverrides: {
				root: {
					...menuItem(theme),
				},
			},
		},
	}
}
