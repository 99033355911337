/*
| Developed by Dirupt
| Filename : list.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import { Theme } from '@mui/material/styles'

/*
|--------------------------------------------------------------------------
| List
|--------------------------------------------------------------------------
*/
export function list(theme: Theme) {
	return {
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					color: 'inherit',
					minWidth: 'auto',
					marginRight: theme.spacing(2),
				},
			},
		},
		MuiListItemAvatar: {
			styleOverrides: {
				root: {
					minWidth: 'auto',
					marginRight: theme.spacing(2),
				},
			},
		},
		MuiListItemText: {
			styleOverrides: {
				root: {
					margin: 0,
				},
				multiline: {
					margin: 0,
				},
			},
		},
	}
}
