/*
| Developed by Dirupt
| Filename : local-storage.service.ts
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

/*
|--------------------------------------------------------------------------
| Service
|--------------------------------------------------------------------------
*/
export class LocalStorageService {
	static localStorageAvailable() {
		try {
			const key = '__some_random_key_you_are_not_going_to_use__'
			window.localStorage.setItem(key, key)
			window.localStorage.removeItem(key)
			return true
		} catch (error) {
			return false
		}
	}

	static localStorageGetItem(key: string, defaultValue = '') {
		const storageAvailable = this.localStorageAvailable()

		let value

		if (storageAvailable) {
			value = localStorage.getItem(key) || defaultValue
		}

		return value
	}
}
