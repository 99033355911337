/*
| Developed by Dirupt
| Filename : i18n.ts
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

'use client'

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { defaultLang } from './config-lang'
// eslint-disable-next-line import/extensions
import translationEn from './langs/en.json'
// eslint-disable-next-line import/extensions
import translationFr from './langs/fr.json'
import { LocalStorageService } from '@/services/local-storage/local-storage.service'

/*
|--------------------------------------------------------------------------
| Get data from local storage
|--------------------------------------------------------------------------
*/
const lng = LocalStorageService.localStorageGetItem('i18nextLng', defaultLang.value)

/*
|--------------------------------------------------------------------------
| Configure
|--------------------------------------------------------------------------
*/
// eslint-disable-next-line import/no-named-as-default-member
void i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources: {
			en: { translations: translationEn },
			fr: { translations: translationFr },
		},
		lng,
		fallbackLng: 'fr',
		debug: false,
		ns: ['translations'],
		defaultNS: 'translations',
		interpolation: {
			escapeValue: false,
		},
	})

export default i18n
