/*
| Developed by Dirupt
| Filename : stepper.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import { Theme } from '@mui/material/styles'

/*
|--------------------------------------------------------------------------
| Stepper
|--------------------------------------------------------------------------
*/
export function stepper(theme: Theme) {
	return {
		MuiStepConnector: {
			styleOverrides: {
				line: {
					borderColor: theme.palette.divider,
				},
			},
		},
	}
}
