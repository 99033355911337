/*
| Developed by Dirupt
| Filename : skeleton.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import { Theme } from '@mui/material/styles'

/*
|--------------------------------------------------------------------------
| Skeleton
|--------------------------------------------------------------------------
*/
export function skeleton(theme: Theme) {
	return {
		MuiSkeleton: {
			styleOverrides: {
				root: {
					backgroundColor: theme.palette.background.neutral,
				},
				rounded: {
					borderRadius: theme.shape.borderRadius * 2,
				},
			},
		},
	}
}
