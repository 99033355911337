/*
| Developed by Dirupt
| Filename : tree-view.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import { Theme } from '@mui/material/styles'

/*
|--------------------------------------------------------------------------
| TreeView
|--------------------------------------------------------------------------
*/
export function treeView(theme: Theme) {
	return {
		MuiTreeItem: {
			styleOverrides: {
				label: {
					...theme.typography.body2,
				},
				iconContainer: {
					width: 'auto',
				},
			},
		},
	}
}
