/*
| Developed by Dirupt
| Filename : iconify.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import React from 'react'
import { Icon } from '@iconify/react'
import Box, { BoxProps } from '@mui/material/Box'
import { IconifyProps } from './types'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
interface Props extends BoxProps {
	icon: IconifyProps
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
const Iconify = React.forwardRef<SVGElement, Props>(({ icon, width = 20, sx, ...other }, ref) => (
	<Box
		ref={ref}
		component={Icon}
		className="component-iconify"
		icon={icon}
		sx={{ width, height: width, ...sx }}
		{...other}
	/>
))

Iconify.displayName = 'Iconify'

export default Iconify
