/*
| Developed by Dirupt
| Filename : progress-bar.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

'use client'

import NProgress from 'nprogress'
import { Suspense, useEffect } from 'react'
import StyledProgressBar from './styles'
import { useRouter, usePathname, useSearchParams } from '@/routes/hooks'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
type PushStateInput = [data: any, unused: string, url?: string | URL | null | undefined]

/*
|--------------------------------------------------------------------------
| Sub component
|--------------------------------------------------------------------------
*/
function NProgressDone() {
	const pathname = usePathname()
	const router = useRouter()
	const searchParams = useSearchParams()

	useEffect(() => {
		NProgress.done()
	}, [pathname, router, searchParams])

	return null
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export default function ProgressBar() {
	useEffect(() => {
		NProgress.configure({ showSpinner: false })

		const handleAnchorClick = (event: MouseEvent) => {
			const targetUrl = (event.currentTarget as HTMLAnchorElement).href

			const currentUrl = window.location.href

			if (targetUrl !== currentUrl) {
				NProgress.start()
			}
		}

		const handleMutation = () => {
			const anchorElements: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('a[href]')

			const filteredAnchors = Array.from(anchorElements).filter((element) => {
				const href = element.getAttribute('href')
				return href && href.startsWith('/')
			})

			filteredAnchors.forEach((anchor) => anchor.addEventListener('click', handleAnchorClick))
		}

		const mutationObserver = new MutationObserver(handleMutation)

		mutationObserver.observe(document, { childList: true, subtree: true })

		window.history.pushState = new Proxy(window.history.pushState, {
			apply: (target, thisArg, argArray: PushStateInput) => {
				NProgress.done()
				return target.apply(thisArg, argArray)
			},
		})
	})

	return (
		<>
			<StyledProgressBar />

			<Suspense fallback={null}>
				<NProgressDone />
			</Suspense>
		</>
	)
}
