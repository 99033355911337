/*
| Developed by Dirupt
| Filename : select.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import { Theme } from '@mui/material/styles'

/*
|--------------------------------------------------------------------------
| Select
|--------------------------------------------------------------------------
*/
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function select(_theme: Theme) {
	return {
		MuiSelect: {
			styleOverrides: {
				icon: {
					right: 10,
					width: 18,
					height: 18,
					top: 'calc(50% - 9px)',
				},
			},
		},
		MuiNativeSelect: {
			styleOverrides: {
				icon: {
					right: 10,
					width: 18,
					height: 18,
					top: 'calc(50% - 9px)',
				},
			},
		},
	}
}
